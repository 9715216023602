import { between, up } from "styled-breakpoints"
import styled from "styled-components"

export const Label = styled.label`
  display: grid;
  grid-template-columns: 2em auto;
  gap: 1em;
  align-items: center;
  text-transform: uppercase;

  ${props => ({
    textTransform: "uppercase",
    color: props.theme.colors.accentText,
    fontFamily: props.theme.fonts.azoSans,
    fontSize: "calc(0.7 * 1.5rem)",
    lineHeight: "calc(0.7 * 2rem)",
    fontWeight: 500,

    [between("md", "xxl")(props)]: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
      margin: "1rem 0 1rem 0",
    },

    [up("xxl")(props)]: {
      fontSize: "calc(1.41 * 1.5rem)",
      lineHeight: "calc(1.41 * 2rem)",
      margin: "calc(1.41 * 1rem) 0 calc(1.41 * 1rem) 0",
    },
  })}

  & + & {
    margin-top: 1em;
  }
`

export const Input = styled.input`
  font: inherit;
  color: currentColor;
  width: 2em;
  height: 2em;
  border: 0.1rem solid currentColor;
  transition: background 0.2s ease-in;

  &:checked {
    background: currentColor;
  }
`
