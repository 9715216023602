import React, { PropsWithChildren } from "react"
import { PropsFrom } from "../../types/utils"
import * as Styled from "./styles"

interface Props {
  labelProps?: PropsFrom<typeof Styled.Label>
  inputProps?: PropsFrom<typeof Styled.Input>
}

const Checkbox: React.FC<PropsWithChildren<Props>> = ({
  children,
  labelProps,
  inputProps,
}) => {
  return (
    <Styled.Label {...labelProps}>
      <Styled.Input type="checkbox" {...inputProps} />
      {children}
    </Styled.Label>
  )
}

export default Checkbox
