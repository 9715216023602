import { between, only, up } from "styled-breakpoints"
import styled from "styled-components"
import { FlexColumn } from "../../components/system/flex-column"
import { FlexRow } from "../../components/system/flex-row"
import Mdx from "../../components/mdx"

export const Layout = styled(FlexRow)`
  flex-wrap: wrap;
  padding: 0 1.8rem;
  background: ${({ theme }) => theme.colors.blue};

  ${between("md", "xl")} {
    padding: 0 3.5rem;
  }

  ${between("xl", "xxl")} {
    padding: 0 6.5rem;
  }

  ${up("xxl")} {
    padding: 0 14rem;
  }
`

export const Content = styled(FlexColumn)`
  flex: 1;
  flex-basis: 100%;
  margin-top: 8.9rem;
  padding-right: 5vw;
  padding-left: 5vw;

  ${up("lg")} {
    flex: 6;

    &:first-of-type form {
      position: sticky;
      top: calc(8.9rem + var(--header-height));
    }

    &[data-side="right"] {
      padding-left: 0;
    }

    &[data-side="left"] {
      padding-right: 0;
    }
  }
`

export const Table = styled(Mdx.table)`
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
  table-layout: fixed;

  &[data-columns="2"] {
    td,
    th {
      &:nth-of-type(2) {
        width: 80%;
      }
    }
  }

  td,
  th {
    border: 0.2rem solid ${({ theme }) => theme.colors.ivoryWhite} !important;

    &:nth-of-type(1) {
      width: 20%;
      min-width: fit-content;
    }
    &:nth-of-type(2) {
      width: 15%;
      min-width: fit-content;
    }

    &:nth-of-type(3) {
      width: 65%;
    }
  }
`

export const Th = styled(Mdx.th).attrs({
  variant: "footnote",
})`
  text-align: start;
  vertical-align: start;

  ${props => ({
    color: props.theme.colors.accentText,
    fontFamily: props.theme.fonts.azoSans,
    fontSize: "calc(1.2rem)",
    lineHeight: "initial",
    margin: 0,

    [between("md", "xxl")(props)]: {
      fontSize: "calc(1.1 * 1.2rem)",
    },

    [up("xxl")(props)]: {
      fontSize: "calc(1.5 * 1.2rem)",
    },
  })};
`

export const Td = styled(Mdx.td).attrs({
  variant: "footnote",
})`
  text-align: start;
  vertical-align: start;

  ${props => ({
    color: props.theme.colors.accentText,
    fontFamily: props.theme.fonts.azoSans,
    fontSize: "calc(1.2rem)",
    lineHeight: "initial",
    margin: 0,

    [between("md", "xxl")(props)]: {
      fontSize: "calc(1.1 * 1.2rem)",
    },

    [up("xxl")(props)]: {
      fontSize: "calc(1.5 * 1.2rem)",
    },
  })};
`
