import React, { useEffect } from "react"
import { PageProps } from "gatsby"
import { ThemeProvider } from "styled-components"
import BasicHeader from "../components/header/basic"
import Seo from "../components/seo"
import GlobalStyles from "../styles/globals"
import { blueTheme } from "../styles/themes"
import * as Styled from "../styles/pages/cookies"
import PageHeading from "../components/page-heading"
import { Typography } from "../components/system/typography"
import Checkbox from "../components/checkbox"
import * as yup from "yup"
import { useFormik } from "formik"
import Button from "../components/button"
import Mdx from "../components/mdx"

const GTAG_OPTIN_KEY = "gtag_optin"
const HIS_OPTIN_KEY = "his_optin"

const CookiesPage: React.FC<PageProps> = () => {
  const formik = useFormik({
    initialValues: { ga: false, his: false },
    validationSchema: yup.object({
      ga: yup.boolean(),
      his: yup.boolean(),
    }),
    onSubmit: ({ ga, his }) => {
      localStorage.setItem(GTAG_OPTIN_KEY, ga ? "0" : "1")
      // @ts-expect-error
      window["ga-disable-${process.env.GA_ID}"] = !ga
      localStorage.setItem(HIS_OPTIN_KEY, his ? "1" : "0")
      window.dispatchEvent(
        new CustomEvent("set-stored-state", {
          detail: { key: HIS_OPTIN_KEY, item: his ? "1" : "0" },
        })
      )
    },
  })

  useEffect(() => {
    const gtagOptin = localStorage.getItem(GTAG_OPTIN_KEY)
    const hisOptin = localStorage.getItem(HIS_OPTIN_KEY)
    const isGACookieFlagSet = gtagOptin === null ? false : !toBoolean(gtagOptin)
    const isHISCookieFlagSet = hisOptin === null ? false : toBoolean(hisOptin)
    formik.setFieldValue("ga", isGACookieFlagSet)
    formik.setFieldValue("his", isHISCookieFlagSet)
  }, [])

  return (
    <ThemeProvider theme={blueTheme}>
      <GlobalStyles variant="blue" />
      <Seo title="Ustawienia Ciasteczek" />
      <BasicHeader divider />
      <Styled.Layout as="main">
        <PageHeading>Polityka Cookies</PageHeading>
        <Styled.Content data-side="left">
          <form onSubmit={formik.handleSubmit}>
            <Checkbox
              inputProps={{
                name: "ga",
                checked: formik.values.ga,
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
              }}
            >
              <span>Zezwolenie na ciasteczka Google Analytics</span>
            </Checkbox>
            <Checkbox
              labelProps={{ style: { marginTop: "3rem" } }}
              inputProps={{
                name: "his",
                checked: formik.values.his,
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
              }}
            >
              <span>Zezwolenie na ciasteczka Hypothesis</span>
            </Checkbox>
            <Button type="submit" style={{ marginTop: "3rem", height: "5rem" }}>
              ZAPISZ
            </Button>
          </form>
        </Styled.Content>
        <Styled.Content data-side="right">
          <Typography as="h2" variant="h2" mt="0 !important">
            Wydawca
          </Typography>
          <Typography mb="4rem" variant="body" typo="azo">
            Wydawcą ksiąki i operatorem strony internetowej jest:
            <br />
            Państwowa Wyższa Szkoła Filmowa, Telewizyjna i Teatralna im. Leona
            Schillera, ul. Targowa 61/63, 90-323 Łódź
          </Typography>
          <Typography as="h2" variant="h2">
            Niezbędne pliki cookies
          </Typography>
          <Typography mb="4rem" variant="body" typo="azo">
            Dane zapisywane w ten sposób są niezbędne do prawidłowego działania
            strony internetowej. Są one zapisywane w pamięci Twojej przeglądarki
            i nie opuszczają Twojego urządzenia. Podczas preglądania, strona
            internetowa uzyskuje do nich dostęp w celu:
          </Typography>
          <Typography mb="4rem" variant="body" typo="azo" as="div">
            <Mdx.ul
              style={{
                font: "inherit",
                margin: "2.2rem",
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              <li>
                zapewnienia prawidłowego działania i pełnej funkcjonalności
              </li>
              <li>prawidłowego wskazywania postępu czytania</li>
            </Mdx.ul>
          </Typography>
          <Typography mb="4rem" variant="body" typo="azo">
            Strona nie zapisuje żadnych informacji umożliwiających
            zidentyfikowanie Ciebie, takich jak adres IP itp., rozumianych jako
            dane osobowe na podstawie Rozporządzenia o Ochronie Danych Osobowych
          </Typography>
          <Typography mb="4rem" variant="body" typo="azo">
            Niezbędne pliki cookies stosowane są domyślnie i zwolnione są z
            obowiązku uzyskania zgody na mocy art. 173 ust. 3 ustawy prawo
            telekomunikacyjne.
          </Typography>
          <Styled.Table data-columns={2}>
            <thead>
              <tr>
                <Styled.Th>Nazwa</Styled.Th>
                <Styled.Th>Opis</Styled.Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Styled.Td>gtag_optin</Styled.Td>
                <Styled.Td>
                  Flaga odpowiadająca za włączenie/wyłączenie Google Analytics.
                </Styled.Td>
              </tr>
              <tr>
                <Styled.Td>splash</Styled.Td>
                <Styled.Td>
                  Flaga odpowiadająca za uruchomienie okładki książki w wersji
                  mobilnej.
                </Styled.Td>
              </tr>
              <tr>
                <Styled.Td>progress</Styled.Td>
                <Styled.Td>
                  Obiekt przechowywany lokalnie na urządzeniu w celu zapisania
                  postępu czytania.
                </Styled.Td>
              </tr>
            </tbody>
          </Styled.Table>
          <Typography as="h2" variant="h2">
            Pliki cookies stron trzecich
          </Typography>
          <Typography as="h3" variant="h3">
            Google Analytics
          </Typography>
          <Typography mb="4rem" variant="body" typo="azo">
            Google Analytics jest narzędiem słuącym do analizy ruchu na stronie
            internetowej.
          </Typography>
          <Typography mb="4rem" variant="body" typo="azo">
            Strona wykorzystuje pliki cookies Google Analytics w celu zbierania
            informacji takich jak liczba indywidualnych użytkowników, liczba
            odsłon strony, czas sesji, odwiedzane podstrony itd.
          </Typography>
          <Typography mb="4rem" variant="body" typo="azo">
            Wydawca nie zbiera i nie przetwarza żadnych Twoich danych osobowych
            w rozumienieniu Rozporządzenia o Ochronie Danych Osobowych
            (Rozporządzenie Parlamentu Europejskiego i Rady UE 2016/679 z dnia
            27 kwietnia 2016), zbieranych przez Google Analytics.
          </Typography>
          <Typography mb="4rem" variant="body" typo="azo">
            Celem zbierania tych danych jest wyłącznie prowadzenie statystyk
            dotyczących popularnosci strony i czytalności publikowanych na niej
            treści.
          </Typography>
          <Typography mb="4rem" variant="body" typo="azo">
            Szczegółowe informacje na temat sposobu przetwarzania danych
            osobowych przez Google znajdziesz{" "}
            <Mdx.a
              href="https://policies.google.com/privacy?hl=pl"
              style={{ font: "inherit", fontStyle: "italic" }}
            >
              pod tym linkiem
            </Mdx.a>
            .
          </Typography>
          <Styled.Table>
            <thead>
              <tr>
                <Styled.Th>Nazwa</Styled.Th>
                <Styled.Th>Czas</Styled.Th>
                <Styled.Th>Opis</Styled.Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Styled.Td>_ga</Styled.Td>
                <Styled.Td>2 lata</Styled.Td>
                <Styled.Td>
                  Główny plik cookie używany przez Google Analytics, umożliwia
                  usłudze odróżnienie jednego odwiedzającego od drugiego.
                </Styled.Td>
              </tr>
              <tr>
                <Styled.Td>_ga_{`{hash}`}</Styled.Td>
                <Styled.Td>2 lata</Styled.Td>
                <Styled.Td>
                  Instalowane przez Google Analytics. Hash odpowiada stumieniowi
                  danych.
                </Styled.Td>
              </tr>
            </tbody>
          </Styled.Table>
          <Typography as="h3" variant="h3">
            Hypothesis
          </Typography>
          <Typography mb="4rem" variant="body" typo="azo">
            Hypothesis jest narzędziem słuącym do tworzenia komentarzy i
            zaznaczeń na stronach internetowych i plikach umieszczonych w sieci.
          </Typography>
          <Typography mb="4rem" variant="body" typo="azo">
            Strona wykorzystuje pliki cookies Hypothesis w celu umożliwienia Ci
            tworzenia, udostępniania i dostępu do komentarzy i zaznaczeń
            wykonywanych przy użyciu Hypothesis.
          </Typography>
          <Typography mb="4rem" variant="body" typo="azo">
            Szczegółowe informacje na temat sposobu przetwarzania danych
            osobowych przez Hypothesis znajdziesz{" "}
            <Mdx.a
              href="https://web.hypothes.is/privacy/"
              style={{ font: "inherit", fontStyle: "italic" }}
            >
              pod tym linkiem
            </Mdx.a>
            .
          </Typography>
          <Typography mb="4rem" variant="body" typo="azo">
            By wyłączyć Hypothesis po zapisaniu preferencji należy przeładować
            stronę.
          </Typography>
        </Styled.Content>
      </Styled.Layout>
    </ThemeProvider>
  )
}

export default CookiesPage

const toBoolean = (val: string) => Boolean(Number(val))
